.bottom-cart-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: grid;
  place-items: center;
  z-index: 1000;
}

.bottom-cart {
  padding: 10px 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.bottom-cart-value{
  color: red;
  font-size: 10px;
  position: absolute;
  margin-bottom: 10px;
  margin-left: 5px;
  font-weight: bold;
}
.cartError{
  position: fixed;
  right: 61px;
  bottom: 30px;
  display: grid;
  place-items: center;
  width: 20%;
  background-color: red;
  border-radius: 5px;
  color: white;
  z-index: 1000;
}