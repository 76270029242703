.login-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  /* background: rgba(0, 0, 0, 0.1); */
  /* z-index: 1000; */
}

.login-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 50%;
  height: 70%;
  display: flex;
  border-radius: 1%;
  overflow: hidden;
}
.login-left {
  width: 60%;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.login-left p {
  position: relative;
  top: 40px;
  left: 75px;
  width: 60%;
  font-size: 12px;
  font-weight: bold;
}
.login-main {
  width: 100%;
}
.login-main input {
  width: 90%;
  padding: 12px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid black;
  /* outline: none; */
}
.login-main button {
  margin-top: 15px;
  width: 100%;
  padding: 12px 20px;
  background-color: #12AD2B;
  color: #fff;
  border-radius: 50px;
  font-weight: bold;
  font-size: 18px;
  border: none;
  outline: none;
  /* margin-bottom: 15px; */
}
.login-right {
  width: 40%;
  height: 100%;
  background-color: #12AD2B;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 0%;
}
.login-right-image {
  height: 25%;
  width: 50%;
  border-radius: 50%;
  background-color: white;
}
.login-right-image img{
  height: 100%;
  width: 85%;
}
.login-right-logo {
  line-height: 0%;
  width: 75%;
}
.login-right-logo img {
  width: 100%;
}
.login-main span{
  margin: 0;
  color: red;
  font-size: 12px;
  margin: 10px;
}
.closebtn{
  background-color: #12AD2B;
}
.closebtn1{
  display: none;
}
@media (max-width:1024px) {
  .login-content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    height: 70%;
    display: flex;
    border-radius: 1%;
    overflow: hidden;
  }
}
@media (max-width: 768px) {
  .closebtn{
    display: none;
  }
  .closebtn1{
    display: flex;
    justify-content: end;
  }
  .login-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .login-content {
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1%;
    overflow: hidden;
  }
  .login-left {
    border-radius: 10px;
    width: 90%;
    height: 90%;
    padding: 20px;
    background-color: #F4C430;
    display: flex;
    flex-direction: column;
  }
  .login-right {
    display: none;
  }
  .login-left p {
    position: relative;
    top: 100px;
    left: 5px;
    width: 90%;
    font-size: 12px;
    font-weight: bold;
  }
  .login-logo h1{
    font-size: 18px;
  }
  .login-logo h2{
    font-size: 14px;
  }
}

@media (max-width: 426px) {
  .closebtn{
    display: none;
  }
  .closebtn1{
    display: flex;
    justify-content: end;
  }
  .login-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .login-content {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1%;
    overflow: hidden;
  }
  .login-left {
    border-radius: 10px;
    width: 90%;
    height: 90%;
    padding: 20px;
    background-color: #F4C430;
    display: flex;
    flex-direction: column;
  }
  .login-right {
    display: none;
  }
  .login-left p {
    position: static;
    /* top: 100px;
    left: 5px;
    width: 90%;
    font-size: 12px;
    font-weight: bold; */
  }
  .login-logo h1{
    font-size: 18px;
  }
  .login-logo h2{
    font-size: 14px;
  }
}

