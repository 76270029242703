.wallet{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: start;
    justify-content: center;
    background-color: #fff;
    padding: 10px 0px;
    overflow-x: auto;
}
.wallet-main{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wallet-left-sub{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wallet-left-sub button{
    margin-left: 20px;
    height: auto;
    border: none;
    color: #fff;
    padding: 5px;
    background-color: #ff5650;
    font-size: large;
    border-radius: 5px;
}