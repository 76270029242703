.addAddress-content {
  width: 40%;
  height: auto;
  padding: 2% 0%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  line-height: 0;
  z-index: 1000;
}
.addAddress-content1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addAddress-content1 h3 {
  margin: 5%;
}
.addAddress-content1 p{
  color: red;
  font-size: 8px;
}
.addAddress-content1 label {
  align-self: self-start;
  margin-top: 1%;
  margin-bottom: 1%;
  font-weight: bold;
}
.addAddress-content1 input {
  width: 100%;
  height: 16px;
  font-size: 14px;
  border-radius: 5px;
  margin: 2%;
  padding: 10px;
}
.addAddress-content1 button {
  width: 30%;
  height: 50px;
  font-size: 18px;
  border-radius: 10px;
  margin: 2%;
  outline: none;
  border: none;
  background-color: #12ad2b;
  color: #fff;
  font-weight: bold;
}
.addAddress-type {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1%;
}
.addAddress-type label {
  display: flex;
  align-self: center;
  padding: 0 5px;
}
.closeBtn {
  width: 90%;
  display: flex;
  justify-content: end;
}

@media (max-width: 426px) {
  .addAddress-content {
    width: 100%;
    height: auto;
    padding: 2% 0%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    line-height: 0;
    z-index: 1000;
  }
  .addAddress-content1 button {
    width: 60%;
    height: 35px;
    font-size: 14px;
    border-radius: 10px;
    margin: 2%;
    outline: none;
    border: none;
    background-color: #12ad2b;
    color: #fff;
    font-weight: bold;
  }
  .addAddress-content1 label {
    align-self: self-start;
    margin-top: 2%;
    margin-bottom: 2%;
    font-weight: lighter;
    font-size: 14px;
  }
  .addAddress-type label {
    display: flex;
    align-self: center;
    padding: 0 5px;
  }
}
