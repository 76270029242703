.customerSupport1 {
  padding: 2%;
}
.active{
  background-color: #1a8a1f;
  border-radius: 10px;
  width: 75%;
  padding-left: 10px;
}
.profileScreen {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}
.profileScreen-main {
  margin-top: 7%;
  height: 90%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  display: flex;
}
.profileScreen-left {
  display: flex;
  flex-direction: column;
  flex: 0.2;
  background-color: #12ad2b;
  color: #fff;
}
.profileScreen-left1 {
  padding-left: 5%;
}
.profileScreen-left1 h3{
  display: flex;
  align-items: center;
  gap: 10px;
}
.profileScreen-leftSub {
  line-height: 2px;
}
.profileScreen-left hr {
  outline: none;
  border: none;
  background-color: #fff;
  width: 90%;
  height: 1.5px;
}
.profileScreen-left2 ul {
  line-height: 50px;
  list-style: none;
  font-weight: bold;
  cursor: pointer;
}
.profileScreen-left3 {
  display: flex;
  justify-content: center;
}
.profileScreen-right {
  flex: 0.8;
}
.profileScreen-mobile-left2{
  display: none;
}

@media (max-width: 426px) {
  .profileScreen-main {
    height: 80%;
    width: 90%;
    border-radius: 10px;
    overflow: auto;
    background-color: white;
    display: block;
  }
  .profileScreen-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #12ad2b;
    color: #fff;
  }
  .profileScreen-left2 {
    display: none;
  }
  .profileScreen-mobile-left2 {
    display: block;
  }
  .profileScreen-mobile-left2 ul {
    line-height: 50px;
    list-style: none;
    font-weight: bold;
  }
  .profileScreen-right{
    display: none;
  }
}
