.orders {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #fff;
  padding: 10px 0px;
  overflow-x: auto;
}
.ordersList {
  width: 90%;
  background-color: #fff;
  border-radius: 5px;
  margin: 0.5%;
  padding: 1% 2%;
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.orderListSub {
  width: 15%;
  display: flex;
  flex-direction: column;
  line-height: 0;
  overflow: hidden;
}

@media (max-width: 426px) {
  .orders {
    font-size: 10px;
    margin-top: 20%;
  }
  .orderListSub {
    width: 15%;
    display: flex;
    flex-direction: column;
    line-height: 12px;
    overflow: hidden;
  }
  .orderListSub h4{
    height: 24px;
  }
}
