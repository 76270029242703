.footer {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  height: auto;
  width: auto;
  background-color: #f6f6f6;
  font-size: 14px;
  font-weight: bold;
  margin-top: 2%;
  /* margin-bottom: 20%; */
}
.footer-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
}
.footer-content {
  height: 250px;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-icons{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.footer-content img{
  width: 50%;
}
.footer-p{
  cursor: pointer;
}
.footer ul{
  display: flex;
  justify-content: space-between;
  list-style: none;
}
.footer ul li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
@media (max-width: 425px){
  .footer {
    margin: 0;
    padding: 0;
    max-width: 425px;
    height: auto;
    background-color: white;
    color: black;
    font-size: 16px;
    font-weight: bold;
    margin-top: 15%;
    margin-bottom: 50%;
  }
  .footer-main{
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 2%;
}
.footer-content {
  height: fit-content;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #fff;
}
.footer-icons{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
}