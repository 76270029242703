.category-container h3{
  padding: 0% 3%;
}
.category-content {
  width: auto;
  height: auto;
  padding: 1% 3%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 0.5fr));
  gap: 0.5rem;
}
.category-container h3{
  display: flex;
  align-items: center;
  gap: 10px;
}
.category-item {
  margin: 2%;
  border-radius: 25px;
  background-color: #fff;
  padding: 5% 0;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.category-item-image {
  height: 100px;
  width: 100px;
  border-radius: 5px;
}
.category-details {
  width: 75%;
}
.category-type {
  height: 30px;
  width: fit-content;
  border-radius: 15px;
  border: none;
  background-color: #fff;
  padding: 10px;
  font-size: 18px;
  margin: 5px 20px;
}
.category-main {
  display: flex;
  overflow-y: auto;
  white-space: nowrap;
}
.category-dropdown {
  background-color: transparent;
  border: none;
  outline: none;
}

@media (max-width: 426px) {
  .category-container h3{
    padding: 5%;
  }
  .category-content {
    display: grid;
    width: auto;
    height: auto;
    padding: 1% 1%;
    grid-template-columns: repeat(auto-fit, minmax(11rem, 0.5fr));
    gap: 0.3rem;
    font-size: 14px;
  }
}
