.newNavbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #333;
  color: #fff;
  padding: 1% 0%;
  margin-bottom: 2%;
  color: black;
  background-color: white;
  z-index: 1000;
  cursor: pointer;
}
.newNavbar1{
  margin: 0 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newSearchbox {
    border: 1px solid black;
    display: inline-block;
    border-radius: 50px;
    padding: 5px 5px 5px 10px;
  }
  
  .newSearchbox input {
    border: none;
    outline: none;
    flex: 1;
    border-radius: 20px;
    padding: 5px;
    background-color: transparent;
    width: 300px;
  }
  
  .newSearchbox button {
    border: none;
    outline: none;
    background-color: black;
    color: white;
    padding: 8px 10px;
    border-radius: 20px;
  }

  .newNavbarRight{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .newNavbarRight1{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .newNavbarRight button{
    border: none;
    outline: none;
    background-color: transparent;
  }
  .newNavbarRight button:hover{
    color: #F4C430;
  }
  .newNavbarRight3 button{
    margin: 0 0;
    font-size: large;
  }
  .newNavbarRight4{
    display: flex;
    justify-content: start;
  }
  .newNavbarRight4 button{
    font-size: x-small;
    justify-content: start;
  }
  .newMobileSearchbox{
    display: none;
  }

  .navbar_searchbox {
    position: relative;
  }
  
  .search-panel {
    position: absolute;
    top: 100%;
    left: 0;
    width: 150%;
    height: auto;
    max-height: 500px;
    background-color: #fff;
    border: none;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: scroll;
    scrollbar-width: none;
    z-index: 1000;
  }
  
  .search-item {
    margin: 1%;
    border-radius: 20px;
    width: auto;
    background-color: #fff;
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-item:hover{
    background-color: #ccc;
  }
  
  .search-item-image {
    height: 75px;
    width: 75px;
    border-radius: 25px;
  }
  
  .search-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .search-cart-button {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  
  .search-cart-button button {
    height: 25px;
    width: 25px;
    border: none;
    color: #fff;
    background-color: #008000;
    outline: none;
    font-size: 24px;
    border-radius: 5px;
    margin-right: 10px;
  }
  .search-cart-button h5 {
    margin: 0 10px;
  }
  .search-type {
    height: 30px;
    width: fit-content;
    border-radius: 15px;
    border: none;
    background-color: rgb(241, 241, 241);
    padding: 10px;
    font-size: 18px;
    margin: 5px 20px;
  }
  
  .search-main {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }
.logo{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
  .logo img{
    height: 50px;
    width: 50px;
  }

  @media (max-width: 480px){
    .newNavbar{
      width: 100%;
    }
    .newSearchbox{
      display: none;
    }
    .newMobileSearchbox{
      border: 1px solid black;
    display: inline-block;
    border-radius: 50px;
    padding: 5px 5px 5px 10px;
    }

    .newMobileSearchbox input {
      border: none;
      outline: none;
      flex: 1;
      border-radius: 20px;
      padding: 5px;
      background-color: transparent;
      min-width: 50px;
      max-width: 150px;
    }
    
    .newMobileSearchbox button {
      border: none;
      outline: none;
      background-color: black;
      color: white;
      padding: 8px 10px;
      border-radius: 20px;
    }
    .newNavbarRight2{
      display: none;
    }
  }