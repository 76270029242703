.custom-datepicker {
  border: none;
  outline: none;
  background-color: wheat;
}

.custom-input {
  display: flex;
  align-items: center;
  border: 1px solid wheat;
  border-radius: 4px;
  padding: 5px;
}

.custom-input input {
  border: none;
  outline: none;
  flex: 1;
  background-color: wheat;
}

.calendar-icon {
  margin-left: 8px;
  color: #495057;
  cursor: pointer;
}


.dateTime{
  background-color: wheat;
  height: 15%;
  width: 100%;
  display: grid;
  padding: 0px 5px;
  border-radius: 15px;
  place-items: center;
  margin-bottom: 10px;
}
.cartScreen {
  background-color: #f5f0f0;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2.5%;
}
.cart-top {
  position: sticky;
  top: 0;
  width: 90%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 15px;
  margin-top: 60px;
  margin-bottom: 1%;
  border-radius: 10px;
  z-index: 100;
}
.cart-top button {
  color: #12AD2B;
  background-color: #fff;
  padding: 10px;
  border: 0.5px solid #12AD2B;
}
.cart-bottom {
  width:90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.cart-left {
  width: 60%;
  margin-right: 1%;
  height: 50%;
}
.cart-right {
  width: 40%;
  height: 100vh;
}
.cart-left-child1 {
  border-radius: 10px;
  overflow: hidden;
}
.cart-items {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 0.5px solid #d4d4d4;
}
.cart-items-left {
  width: 100%;
  display: flex;
}
.cart-items-image {
  margin: 1% 2%;
}
.cart-item-image {
  height: 100px;
  width: 100px;
}
.cart-left-child2 {
  margin: 1px;
  background-color: #fff;
}
.cart-items-right{
  display: flex;
  justify-content: space-between;
}
.cart-coupons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}
.cart-calculation {
  width: 100%;
  padding: 5% 1%;
  background-color: #fff;
  border-radius: 10px;
  display: grid;
  place-items: center;
  margin-bottom: 5px;
}
.cart-calculation table {
  border-collapse: collapse;
  margin-top: 10px;
  width: 90%;
  font-size: 12px;
}
.cart-calculation table tr {
  height: 24px;
}
.cart-calculation table td {
  text-align: start;
}
.cart-calculation hr {
  border: 1px dotted rgb(190, 190, 190);
  width: 120%;
  margin: 15px 0px;
}
.cart-calculation-total {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.cartAddress {
  width: 100%;
  height: 25%;
  padding: 5% 1%;
  background-color: #fff;
  border-radius: 10px;
  display: grid;
  place-items: center;
}
.cartAddress hr {
  border: 0.5px solid grey;
  margin: 3%;
}
.cartAddressView {
  color: black;
  display: flex;
  width: 90%;
  font-size: 14px;
}
.address-button {
  background-color: transparent;
  border: none;
  color: #12AD2B;
  font-weight: bold;
}
.paymentButton {
  width: 90%;
  height: 50%;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.paymentButton button {
  background-color: transparent;
  border: none;
  font-weight: bold;
  padding: 0.7rem 0rem;
  background-color: #12AD2B;
  color: #fff;
}
.addressSelect-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.addressSelect-content {
  background: #fff;
  padding: 0px 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 25%;
  height: auto;
}
.addressList {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 2%;
  padding: 10px 20px;
  font-size: 14px;
}
.addAddress {
  background-color: #fff;
  border: none;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addressbox {
  height: 300px;
  overflow: scroll;
}
.address-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paymentButton{
  height: 48px;
}
.paymentButton button{
  width: 100%;
  height: 100%;
}
.instant{
  width: 90%;
  margin-top: 20px;
  padding: 0px 10px;
  background-color: #c6ffcf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.instant1{
  list-style: none;
  line-height: 1px;
}

@media (max-width: 992px) {
  .cartScreen{
    margin-top: 16%;
  }
  .dateTime{
    height: 20%;
  }
}

@media (max-width: 426px) {
  .cartScreen{
    margin-top: 16%;
  }
  .cart-top {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cart-bottom {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .cart-left {
    width: 100%;
    height: auto;
    align-self: center;
  }
  .cart-right {
    width: 100%;
    height: 45vh;
  }
  .cartAddress {
    width: 100%;
    padding: 0px 0px 30px 0px;
    bottom: 0px;
    position: fixed;
  }
  .cart-calculation {
    width: 100%;
    padding: 5% 0%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }
  .addressSelect-content {
    background: #fff;
    padding: 0px 20px 20px 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    height: auto;
  }
  .dateTime{
    margin-top: 10px;
    height: 40%;
  }
  .cart-item-image{
    height: 30px;
    width: 30px;
  }
  .cart-items-image{
    display: flex;
    align-items: center;
  }
  .cart-bottom{
    margin-bottom: 45%;
  }
}
