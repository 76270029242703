.newCategory{
    display: flex;
    height: 100vh;
    width: 100vw;
    margin-top: 7%;
}

.newCategoryLeft{
    height: 100vh;
    width: 100%;
    flex: 0.2;
    background-color: white;
    padding-left: 2%;
    overflow: hidden;
}
.newCategoryRight h1{
  display: flex;
  align-items: start;  
  justify-content: start;
  margin-left: 2%;
}
.newCategoryLeft1{
    height: 94%;
    overflow-y: scroll;
    width: 100%;
}
.newCategoryRight{
    height: 100vh;
    width: 100%;
    flex: 0.8;
    overflow-y: scroll;
    background-color: white;
}

.newCategoryType{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4%;
    gap: 10px;
    font-weight: 900;
}

.newCategoryType:hover{
    background-color: #5cb85c;
}
@media (max-width: 1024px) {
    .newCategory{
        margin-top: 10%;
    }
}
@media (max-width: 480px) {
    .newCategory{
        margin-top: 20%;
    }
    .newCategoryType{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10%;
        padding-top: 5px;
        font-size: 10px;
        gap: 10px;
        font-weight: 900;
        border: 1px solid #c9c9c9;
        border-radius: 10px;
        text-align: center;
        padding-bottom: 5px;
    }
    .newCategoryLeft{
        width: 75px;
        flex: 0.2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .newCategoryRight{
        flex: 0.8;
    }
}