.profileDetails{
    padding: 5% 3%;
}
.profileDetails2{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-between;
}
.profileDetails2 input{
    width: 100%;
    margin: 10px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    padding: 0px 25px;
}
.enableEdit{
    margin: 10px;
    height: 50px;
    font-size: 18px;
    background-color: #12ad2b;
    color: white;
    border: none;
    width: 50%;
    border-radius: 5px;
}
.disableEdit{
    margin: 10px;
    height: 50px;
    font-size: 18px;
    background-color: rgb(204, 204, 204);
    color: rgb(161, 161, 161);
    border: none;
    width: 50%;
    border-radius: 5px;
}
.profileDetails0{
    display: flex;
    justify-content: space-between;
}
.profileDetails0 button{
    height: 24px;
    outline: none;
    border: none;
    background-color: #0165fc;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
}
.popupScreen{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;   
}

@media (max-width:425px) {
    .profileDetails{
        margin-top: 20%;
    }
}
