.location-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.location-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  display: grid;
  place-items: center;
}

.location-content label {
  margin: 10px 10px 40px 10px;
  font-size: 24px;
}

.location-content select {
  width: 100%;
  padding: 10px;
  height: 50px;
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.location-select option {
  color: black;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 24px;
}

.location-content button {
  width: 75%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #F4C430; */
  font-size: 24px;
  outline: none;
  border: none;
  border-radius: 5px;
}
/* .location-content label{
  height: 20%;
  width: 100%;
  margin: 0 auto;
} */
@media (max-width: 768px) {
  .location-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  .location-content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 75%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
  }
  .location-content label{
    font-size: 16px;
    font-weight: bold;
  }
  .location-content  select{
    font-size: 16px;
  }
  .location-content button{
    font-size: 16px;
    height: 40px;
  }
}
