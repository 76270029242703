.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 30%;
  height: auto;
  background-color: #fff;
  display: flex;
  border-radius: 5%;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.image{
  height: 50%;
  width: 50%;
    overflow: hidden;
}
.content1{
  display: grid;
  place-items: center;
  line-height: 0;
}