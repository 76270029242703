.addresses {
  width: 100%;
  height: 100%;
  padding: 0;
}
.addresses1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 2%;
  padding: 10px 20px;
  font-size: 14px;
}
.addresses-right {
  width: 2%;
}
.addresses-right button {
  margin: 5px;
  height: 35px;
  width: 35px;
  border-radius: 25px;
  border: none;
}
.addresses-right-button1 {
  background-color: #0165fc;
}
.addresses-right-button2 {
  background-color: #e10600;
}
.updateAddress-content {
  width: 40%;
  height: auto;
  padding: 2% 0%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  line-height: 0;
}
.updateAddress-content1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.updateAddress-content1 h3 {
  margin: 5%;
}
.updateAddress-content1 label {
  align-self: self-start;
  margin-top: 2%;
  margin-bottom: 2%;
}
.updateAddress-content1 input {
  width: 100%;
  height: 20px;
  font-size: 18px;
  border-radius: 5px;
  margin: 2%;
  padding: 10px;
}
.updateAddress-content1 button {
  width: 30%;
  height: 50px;
  font-size: 18px;
  border-radius: 10px;
  margin: 2%;
  outline: none;
  border: none;
  background-color: #12ad2b;
  color: #fff;
  font-weight: bold;
}
.updateAddress-type {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1%;
}
.updateAddress-type label {
  display: flex;
  align-self: center;
  padding: 0 5px;
}
.closeBtn {
  width: 90%;
  display: flex;
  justify-content: end;
}

@media (max-width: 426px) {
  .addresses {
    width: 90%;
    height: 100%;
    padding: 0;
    margin-top: 20%;
  }
  .addresses1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 1%;
    padding: 5px 10px;
    font-size: 12px;
  }
  .addresses-right {
    width: 4%;
  }
  .addresses-right button {
    margin: 5px;
    height: 35px;
    width: 35px;
    border-radius: 25px;
    border: none;
  }
  .updateAddress-content {
    width: 90%;
    height: auto;
    padding: 2% 0%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    line-height: 0;
  }
  .updateAddress-content1 input {
    width: 100%;
    height: 20px;
    font-size: 12px;
    border-radius: 5px;
    margin: 2%;
    padding: 10px;
  }
}
