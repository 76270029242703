.singleProduct {
  padding: 10px 0%;
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin-top: 7%;
}

.singleProduct-content {
  padding: 10px;
  margin: 20px 0;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.singleProduct-image {
  flex: 0.3;
  display: grid;
  place-items: center;
  margin-bottom: 20px;
}

.singleProduct-description {
  width: 90%;
  padding: 20px;
}

.singleProduct-description p {
  width: 100%;
  max-width: 100%;
  margin: 0;
  line-height: 1.5;
}

.singleProduct-item-image {
  max-width: 100%;
  height: auto;
}

@media only screen and (min-width: 600px) {
  .singleProduct-content {
    flex-direction: row;
  }

  .singleProduct-image {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .singleProduct-description p {
    width: 60%;
    max-width: 60%;
  }
}

@media only screen and (min-width: 900px) {
  .singleProduct-content {
    flex-direction: row;
  }

  .singleProduct-image {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .singleProduct-description p {
    width: 60%;
    max-width: 60%;
  }
}

@media (max-width:425px) {
  .singleProduct{
    margin-top: 20%;
  }
}

