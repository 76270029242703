.scrolling-text {
  /* Set the width and height of the container */
  width: 100%;
  height: 50px;
  /* Set the text to be centered */
  text-align: center;
  color: red;
  /* Apply animation */
  animation: scroll-left 35s linear infinite;
}
.popupScreens{
  width: 30%;
  height: 25%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid grey;
}
.popupScreen-button{
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
@media (max-width: 425px) {
  .popupScreens{
    width: 75%;
    height: 25%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid grey;
  }
  .popupScreen-button{
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: #f6f6f6;
  font-family: "Comfortaa";
}
input {
  font-family: "Comfortaa";
}
button {
  font-family: "Comfortaa";
}
select {
  font-family: "Comfortaa";
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* .cart-button {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-button button {
  margin: auto;
  height: 40px;
  width: 40px;
  border: none;
  background-color: #1b8d;
  outline: none;
  font-size: 24px;
  border-radius: 5px;
} */

.cart-button {
  display: flex;
  align-items: center;
}

.cart-button button {
  height: 25px;
  width: 25px;
  border: none;
  color: #fff;
  background-color: #008000;
  outline: none;
  font-size: 24px;
  border-radius: 5px;
  margin-right: 10px;
}

.cart-button h5 {
  margin: 0 10px;
}

.product-main {
  padding-left: 20px;
}
*::-webkit-scrollbar {
  width: 0px;
}
.product-content {
  display: flex;
  /* padding: 1%; */
  height: auto;
  width: auto;
  overflow-x: auto;
}
.product-item {
  width: 250px;
  height: 90%;
  margin-right: 20px;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.product-item-image {
  height: 100px;
  width: 100px;
  border: none;
  outline: none;
  border-radius: 25px;
}
.product-details {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-name {
  height: auto;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.product-price {
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .product-content {
    display: flex;
    height: auto;
    width: auto;
    overflow-x: auto;
  }
  .product-item {
    width: 200px;
    height: 90%;
    margin-right: 5px;
    border-radius: 25px;
    background-color: #fff;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .product-items {
    width: 70%;
    margin: 5px;
  }
  .product-item-image {
    height: 50px;
    width: 50px;
    border: none;
    outline: none;
    border-radius: 25px;
  }
}